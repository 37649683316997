import React from 'react';
import { Flex, Heading, Box, Center, IconButton, Table, Thead, Tbody, Tr, Th, Td, useBreakpointValue } from '@chakra-ui/react';
import { ImCross } from 'react-icons/im';
import { borderColor } from './colors';

const Cart = ({ selectedSeats, handleRemoveSeat }) => {
  const subTotal = selectedSeats.reduce((acc, seat) => acc + seat.price, 0);
  const fee = 0.5 * selectedSeats.filter(seat => seat.stato !== "Disabili").length;
  const total = subTotal + fee;
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const tableFontSize = useBreakpointValue({ base: "xs", md: "md" });

  return (
    <Center mb={{ base: "20px", md: "0px" }}>
      <Flex direction="column" align="center">
        <Box
          border="2px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          width={{ base: "100%", md: "100%" }}
          maxW="600px"
          position="relative"
        >
          <Box
            position="absolute"
            top="-18px"
            left="50%"
            transform="translateX(-50%)"
            px={3}
            bg="black"
          >
            <Heading as="h3" size="lg" textTransform={"uppercase"} textAlign="center" color={borderColor}>
              Carrello
            </Heading>
          </Box>

          {/* Contenitore Scrollabile */}
          <Box overflowX="auto" width="100%">
            <Table variant="simple" color="gray.400" fontSize={tableFontSize} mt={10} size={buttonSize}>
              <Thead>
                <Tr>
                  <Th textAlign="center" color={borderColor} px={{base:2, md:4}}>Fila</Th>
                  <Th textAlign="center" color={borderColor} px={{base:2, md:4}}>Posto</Th>
                  <Th textAlign="center" color={borderColor} px={{base:2, md:4}}>Sezione</Th>
                  <Th textAlign="center" color={borderColor} px={{base:2, md:4}}>Prezzo</Th>
                  <Th px={{base:2, md:4}}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedSeats.map(({ row, number, price, stato, sezione }) => (
                  <Tr key={`${row}-${number}-${sezione}`}>
                    <Td textAlign="center" px={{base:2, md:4}} whiteSpace="nowrap">{row}</Td>
                    <Td textAlign="center" px={{base:2, md:4}} whiteSpace="nowrap">{stato === "Disabili" ? "Disabili" : number}</Td>
                    <Td textAlign="center" px={{base:2, md:4}} whiteSpace="nowrap">{sezione}</Td>
                    <Td textAlign="center"  px={{base:2, md:4}} whiteSpace="nowrap">{price.toFixed(2).toString().replace(".", ",")} €</Td>
                    <Td textAlign="center" px={{base:2, md:4}}>
                      <IconButton
                        colorScheme={borderColor}
                        _hover={{ color: "white", bgColor: borderColor }}
                        aria-label="Rimuovi"
                        size={buttonSize}
                        icon={<ImCross />}
                        onClick={() => handleRemoveSeat(row, number, sezione)}
                      />
                    </Td>
                  </Tr>
                ))}
                <Tr>
                  <Td colSpan={4} borderBottom="none"></Td>
                </Tr>
                <Tr>
                  <Td colSpan={3} textAlign="left" fontWeight="bold" borderBottom="none">Subtotale:</Td>
                  <Td textAlign="right" fontWeight="bold" borderBottom="none">{subTotal.toFixed(2).toString().replace(".", ",")} €</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3} textAlign="left" fontWeight="bold" borderBottom="none">Commissioni Servizi:</Td>
                  <Td textAlign="right" fontWeight="bold" borderBottom="none">{fee.toFixed(2).toString().replace(".", ",")} €</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3} textAlign="left" borderBottom="none" color={borderColor} fontWeight={"bold"}>Totale:</Td>
                  <Td textAlign="right" borderBottom="none" fontWeight="bold" color={borderColor}>{total.toFixed(2).toString().replace(".", ",")} €</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
    </Center>
  );
};

export default Cart;
