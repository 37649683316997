import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage.tsx';
import Successo from './components/Successo.tsx';
import Cancel from './components/Cancel.tsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/success" element={<Successo/>} />
        <Route path="/cancel" element={<Cancel/>} />
      </Routes>
    </Router>
  );
}

export default App;
