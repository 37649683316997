import React, { useState } from 'react';
import { Button, Input, FormControl, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useToast, Text } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { borderColor } from './colors';

// C'è da modificare anche server/index.js nelle prime righe
//const stripePromise = loadStripe('pk_test_51P6T3SBrfvk93J7B4lVKGU27brxlBJRfXVJynPx5fSQTCuDFMfbsGb4rbcInIUj03o8XWhfzDqi33ZzYK6Gr3HEo00dcg9VNwM');
const stripePromise = loadStripe('pk_live_51P6T3SBrfvk93J7BzRSJstTDvuDb4cwybF3BO4PMxTCsOIkM7mC7yq71ImG5VNSEZQvMCB4R1Bz361NFtxskPgam002eTLIRis')

const CheckoutButton = ({ seatIds }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();
  const server = 'https://scorlenticket-server.vercel.app';
  //const server = 'http://localhost:5001';

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateTelefono = (telefono) => /^\d{10}$/.test(telefono);

  const handleSeatValidation = async () => {
    setLoading(true);
    const response = await fetch(`${server}/check-seats`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ seatIds }),
    });

    const { success } = await response.json();
    setLoading(false);

    return success;
  };

  const handleCheckout = async () => {
    if (!name) {
      setError('Per favore, inserisci il tuo nome.');
      return;
    }
    if (!surname) {
      setError('Per favore, inserisci il tuo cognome.');
      return;
    }
    if (!validateEmail(email)) {
      setError('Per favore, inserisci un\'email valida.');
      return;
    }
    if (!validateTelefono(telefono)) {
      setError('Per favore, inserisci un numero di telefono valido.');
      return;
    }

    // Attendi la validazione dei posti
    const isSeatValid = await handleSeatValidation();
    
    // Se la validazione fallisce, mostra il modal di errore e fermati qui
    if (!isSeatValid) {
      setIsErrorModalOpen(true);
      return;
    }

    setLoading(true);

    const response = await fetch(`${server}/create-checkout-session`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ seatIds, customerEmail: email, customerName: name, customerSurname: surname, customerTelefono: telefono }),
    });

    const { id } = await response.json();
    const stripe = await stripePromise;
    if (stripe) {
      await stripe.redirectToCheckout({ sessionId: id });
    }

    setLoading(false);
    setIsModalOpen(false);
  };

  const handleButtonClick = async () => {
    if (seatIds.length === 0) {
      toast({
        title: "Nessun posto selezionato",
        status: "error",
        isClosable: true,
        duration: 2000,
      });
      return;
    }

    if(seatIds.length > 40) {
      toast({
        title: "Limite di posti superato",
        description: "Hai selezionato più di 40 posti. Per favore, riduci il numero di posti selezionati.",
        status: "error",
        isClosable: true,
        duration: 2000,
      });
      return;
    }
    
    //const isValid = await handleSeatValidation();
    const isValid = true;

    if (isValid) {
      setIsModalOpen(true);
    }
    
  };

  return (
    <>
      <Button 
        bg={borderColor}
        color="white"
        mt="1.5vh"
        width={"100%"}
        _hover={{ bg: 'transparent', color: borderColor, border: `2px solid ${borderColor}` }}
        onClick={handleButtonClick}
        disabled={loading || seatIds.length === 0}
      >
        {loading ? 'In Elaborazione...' : 'CONFERMA'}
      </Button>

      {/* Modal per inserire i dati utente */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inserisci i tuoi dati</ModalHeader>
          <ModalBody>
            <FormControl mb={4}>
              <Input placeholder="Nome" value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl mb={4}>
              <Input placeholder="Cognome" value={surname} onChange={(e) => setSurname(e.target.value)} />
            </FormControl>
            <FormControl mb={4}>
              <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mb={4}>
              <Input placeholder="Telefono" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
            </FormControl>
            {error && <Text color="red">{error}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCheckout} disabled={loading}>
            {loading ? 'In Elaborazione...' : 'Conferma'}
            </Button>
            <Button onClick={() => setIsModalOpen(false)}>Annulla</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal di errore */}
      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Posti non disponibili</ModalHeader>
          <ModalBody>
            <Text>Alcuni posti che hai selezionato sono in fase di prenotazione da un altro utente. Ricarica la pagina e riprova.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={() => window.location.reload()}>
              Ricarica Pagina
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CheckoutButton;
