import React, { useEffect, useState } from 'react';
import { Button, Flex, Text, Center, UnorderedList, ListItem, Heading } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { fetchSeats, bookSeat, sendEmail, updateSeat, checkAllSeatsAvailable } from '../redux/actions/seatAction';
import Legenda from './Legenda.tsx';
import Carrello from './Carrello.tsx';
import { HallSkeleton } from './HallSkeleton.tsx';
//import { v4 as uuidv4 } from 'uuid';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@chakra-ui/react';
import Stripe from './Stripe.tsx';
import { borderColor } from './colors.js';
import { format } from 'date-fns';
import { it } from 'date-fns/locale'; // Importa la localizzazione italiana
import TrentoHall from './TrentoHall.js';
import FaiHall from './FaiHall.js';

var timerId = -1;

const SeatList = ({ seats, cartSeats, fetchSeats, bookSeat, sendEmail, updateSeat, checkAllSeatsAvailable }) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [timer, setTimer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  // eslint-disable-next-line
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlreadyTaken, setIsAlreadyTaken] = useState(false);
  const [firstModal, setFirstModal] = useState(false); //TODO Modal di benvenuto
  const [closeBooking] = useState(true); //TODO Modal Chiusura prenotazioni
  const timeLimit = 600000; // 10 minuti
  const [isDateModalOpen, setIsDateModalOpen] = useState(false); // Modal per la selezione della data
  const [selectedDate, setSelectedDate] = useState(null); // Data selezionata
  // ****************************************************************************************** //
  
  useEffect(() => {
    if (selectedDate) {  // Verifica che la data sia stata selezionata
      setIsLoading(true); // Mostra lo stato di caricamento
      fetchSeats(selectedDate) // Recupera i posti per la data selezionata
        .then(() => setIsLoading(false)) // Nasconde il caricamento quando i dati arrivano
        .catch(error => {
          console.error("Errore nel recupero dei posti:", error);
          setIsLoading(false); // Nasconde il caricamento anche in caso di errore
        });
    }
  }, [selectedDate, fetchSeats]);

  // Ritorna Giorno dd-MM-yyyy
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return format(date, "EEEE dd-MM-yyyy", { locale: it }); // Formato: "Sabato 08-03-2025"
  };

  const handleSeatClick = async (row, number, sezione) => {
    if(selectedSeats.find(seat => seat.row === row && seat.number === number && seat.sezione === sezione)) return;

    // Verifica che il posto selezionato sia disponibile
    const seat = seats.find((seat) => seat.fila === row && seat.numero === number && seat.sezione === sezione);
  
    if (seat && (seat.stato === "Disponibile" || seat.stato === "Disabili")) {
      const price = seat.stato === "Disponibile" ? seat.prezzo : 0;
  
      // Aggiorna l'array dei posti selezionati mantenendo anche stato e sezione
    const updatedSeats = [...selectedSeats, { 
      row, 
      sezione: seat.sezione,
      number, 
      price, 
      date: selectedDate, 
      stato: seat.stato 
    }].sort((a, b) => {
      if (a.row < b.row) return -1;
      if (a.row > b.row) return 1;
      return a.number - b.number;
    });
  
      setSelectedSeats(updatedSeats);
  
      if (seat.stato === "Disponibile") {
        seat.stato = "In prenotazione";
      }
  
      // Se non c'è già un timer in esecuzione, avvialo
      if (timer === null) {
        const startTime = Date.now();
        const endTime = startTime + timeLimit; // Tempo limite: timeLimit minuti
        setTimer(0);
        timerId = setInterval(() => updateTimer(endTime), 1000); // Aggiorna il timer ogni secondo
        setTimeLeft(Math.ceil((endTime - Date.now()) / 1000)); // Calcola il tempo rimanente iniziale
      }
      console.log("selectedSeats", selectedSeats);
    }
  };

  // Funzione per aggiornare il timer
  const updateTimer = (endTime) => {
    const timeLeftInSeconds = Math.ceil((endTime - Date.now()) / 1000);
    setTimeLeft(timeLeftInSeconds);
    if (timeLeftInSeconds <= 0) {
      handleTimeout();
    }
  };

  // Funzione per gestire il timeout
  const handleTimeout = () => {
    clearInterval(timerId);
    setTimer(null);
    setIsModalOpen(false);
    setSelectedSeats([]);
    setTimeLeft(0);
    fetchSeats(selectedDate);
  };

  // Funzione per raggruppare i posti per fila
  const groupSeatsByRow = () => {
    const seatsByRow = {};
    seats.forEach(seat => {
      if (!seatsByRow[seat.fila]) {
        seatsByRow[seat.fila] = [];
      }
      seatsByRow[seat.fila].push(seat);
    });
    // Ordina i posti all'interno di ciascuna fila per numero
    Object.keys(seatsByRow).forEach(row => {
      seatsByRow[row].sort((a, b) => {
        const seatNumberA = parseInt(a.numero);
        const seatNumberB = parseInt(b.numero);
        return seatNumberA - seatNumberB;
      });
    });
    return seatsByRow;
  };

  // Funzione per controllare se un posto è presente nel carrello
  const isSeatInCart = (row, number, sezione) => {
    //console.log('isSeatInCart', row, number, selectedDate);
    //console.log(cartSeats);
    return cartSeats.some(seat => seat.fila === row && seat.numero === number && seat.data === selectedDate && seat.sezione === sezione);
  };

  const handleRemoveSeat = (row, number, sezione) => {
    const seat = seats.find(seat => seat.fila === row && seat.numero === number && seat.sezione === sezione);
    if (seat.stato !== "Disabili") {
      seat.stato = "Disponibile";

      /* Non serve più in quanto nel database non si aggiorna lo stato del posto

      seat.lockedUntil = "1970-01-01T00:00:00.000+00:00"; // Reset del campo lockedUntil
  
      // Aggiorna il posto nel database
      updateSeat(row, number, "", "1970-01-01T00:00:00.000+00:00"); // SessionId vuoto e lockedUntil resettato
      */

      // Rimuovi il posto dal carrello
      const updatedSelectedSeats = selectedSeats.filter(seat => !(seat.row === row && seat.number === number && seat.sezione === sezione));
      setSelectedSeats(updatedSelectedSeats);
  
      // Se non ci sono più posti selezionati, resetta anche il timer
      if (selectedSeats.length === 1) {
        handleTimeout();
      }
    }
    else if(seat.stato === "Disabili"){
      // Rimuovi il posto dal carrello
      const updatedSelectedSeats = selectedSeats.filter(seat => !(seat.row === row && seat.number === number && seat.sezione === sezione));
      setSelectedSeats(updatedSelectedSeats);
  
      // Se non ci sono più posti selezionati, resetta anche il timer
      if (selectedSeats.length === 1) {
        handleTimeout();
      }
    }
  };

  const seatsByRow = groupSeatsByRow();

  return (
    <Flex bg="black" direction="column" align={"center"} h="auto">
      <Center mt="20px">
        <Flex direction={"column"} align={"center"} maxWidth={{base:"400px", md:"900px"}} width="100%" px={4}>
          {timeLeft > 0 && <Text fontSize={{base:"8px", md:"20px"}} mb={{base:"20px", md:"30px"}} color="gray.400">Tempo rimanente per questa prenotazione: {timeLeft} secondi</Text>}
          {/* Mostra i posti */}
          {isLoading && <HallSkeleton />}
          {!isLoading && selectedDate && 
            // TODO: Per FAI DELLA PAGANELLA usare il seguente codice
            //<FaiHall seatsByRow={seatsByRow} handleSeatClick={handleSeatClick} isSeatInCart={isSeatInCart} />
            // ****************************************************************************************** //

            // TODO: Per il TEATRO SOCIALE DI TRENTO usare il seguente codice
            <TrentoHall seatsByRow={seatsByRow} handleSeatClick={handleSeatClick} isSeatInCart={isSeatInCart} />
            // ****************************************************************************************** //
            
          }
        </Flex>
      </Center>
      <Text color="white" mt={4} textTransform={'capitalize'}>Data Selezionata: {formatDate(selectedDate)}</Text>
      <Legenda />        
      <Flex direction="column" mb="30px" alignItems="center">
        <Carrello selectedSeats={selectedSeats} handleRemoveSeat={handleRemoveSeat} />
        <Stripe seatIds={selectedSeats}/>
      </Flex>
            {/* Modal di benvenuto con le istruzioni */}
            <Modal isOpen={firstModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.100" color="gray.900">
            <ModalHeader p={8}>
                <Text textTransform={"uppercase"} textAlign={"center"}>Benvenuto</Text>
                <Text  textTransform={"uppercase"} textAlign={"center"} fontWeight={"thin"} fontSize={{base:"sm", md:"md"}}>Istruzioni per la tua prenotazione</Text>
            </ModalHeader>
            <ModalBody textAlign={"left"}>
                <UnorderedList>
                  <ListItem>Seleziona sulla piantina il posto che desideri</ListItem>
                  <ListItem>Controlla nella sezione "Carrello" e conferma la tua prenotazione</ListItem>
                  <ListItem>Compila tutti i campi richiesti e invia la prenotazione</ListItem>
                  <ListItem>Effettua il pagamento e attendi la conferma</ListItem>
                  <ListItem>Controlla la tua email per trovare il riepilogo della tua prenotazione</ListItem>
                </UnorderedList>
                <Heading p={4} color="red" textTransform="uppercase" textAlign={"center"} fontSize={{base:"md", md:"lg"}}>Attenzione</Heading>
                <UnorderedList color="red">
                  <ListItem>Puoi eliminare i posti selezionati tramite la X presente nella sezione "Carrello"</ListItem>
                  <ListItem>Non è necessario stampare la prenotazione, è sufficiente esibirla dal tuo dispositivo</ListItem>
                </UnorderedList>
            </ModalBody>
            <ModalFooter justifyContent={"center"}>
            <Button bg={borderColor} color="white" onClick={() => { setFirstModal(false); setIsDateModalOpen(true); }}>
              Avanti
            </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal di chiusura prenotazioni */}
      <Modal isOpen={closeBooking} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.100" color="gray.900">
            <ModalHeader>
                <Text textTransform={"uppercase"} textAlign={"center"}>PREVENDITE CHIUSE</Text>
            </ModalHeader>
            <ModalBody textAlign={"center"}>
                <Text>per il tuo ingresso senza prevendita, recati DOMENICA 30 MARZO al Teatro San Marco<br/>Apertura porte ore 20:30
                  <br/>TI ASPETTIAMO!</Text>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal di selezione data */}
      <Modal isOpen={isDateModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.100" color="gray.900">
          <ModalHeader>
            <Text textTransform="uppercase" textAlign="center">Seleziona una data</Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text>Scegli la data per vedere i posti disponibili:</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-around">
            <Flex direction="column" align="center" gap={4}>
              {/*<Button background={"#c6e9f9"} _focus={{shadow:"none"}} w="100%" onClick={() => { setSelectedDate('2025-03-08'); setIsDateModalOpen(false); }}>
                Sabato 8 Marzo - FAI DELLA PAGANELLA
              </Button>*/}
              {/*<Button background={"#a5d9fd"} _focus={{shadow:"none"}} onClick={() => { setSelectedDate('2025-03-09'); setIsDateModalOpen(false); }}>
                Domenica 9 Marzo - FAI DELLA PAGANELLA
              </Button>*/}
              <Button background={"#c6e9f9"} _focus={{shadow:"none"}} w="100%" onClick={() => { setSelectedDate('2025-03-30'); setIsDateModalOpen(false); }}>
                Domenica 30 Marzo - TRENTO
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal di errore posti già prenotati */}
      <Modal isOpen={isAlreadyTaken} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                <Text>Posto già prenotato</Text>
            </ModalHeader>
            <ModalBody>
                I posti selezionati sono stati prenotati da un altro utente, ci scusiamo per l'inconveniente. Si prega di selezionare altri posti.
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={() => setIsAlreadyTaken(false)}>
                Chiudi
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

const mapStateToProps = state => ({
  seats: state.seat.seats,
  cartSeats: state.seat.cartSeats
});

const mapDispatchToProps = {
  fetchSeats,
  bookSeat,
  sendEmail,
  updateSeat,
  checkAllSeatsAvailable
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatList);
