import React from "react";
import { Box, Text, Center, Button, Flex } from "@chakra-ui/react";
import { FaWheelchair } from "react-icons/fa";
import Screen from "./Screen.tsx";
import { getColorFromStatus, getHoverStyle } from "./seatUtils.js";

export default function TrentoHall({ seatsByRow, isSeatInCart, handleSeatClick }) {
  return (
    <Center as="flex" flexDirection="column" color="white" p={4} borderRadius="md">
      <Screen width={{ base: "260px", md: "800px" }} height={{ base: "30px", md: "100px" }} text="Palcoscenico" />
      <Box p={4} textAlign="center">
        <SeatingArea 
          section="PLATEA" 
          seatsByRow={seatsByRow} 
          isSeatInCart={isSeatInCart} 
          handleSeatClick={handleSeatClick} 
          separatePlatea
        />
        <Box p={4} mt={4} textAlign="center" border="2px" borderColor="white" borderRadius="md">
          <Text fontSize={{base:"sm", md:"xl"}} fontWeight="semibold" color="white" alignSelf="center" my={2}>GALLERIA</Text>
          <SeatingArea 
            section="GALLERIA" 
            seatsByRow={seatsByRow} 
            isSeatInCart={isSeatInCart} 
            handleSeatClick={handleSeatClick} 
          />
        </Box>
      </Box>
    </Center>
  );
}

const SeatingArea = ({ section, seatsByRow, isSeatInCart, handleSeatClick, separatePlatea }) => {
  const filteredRows = Object.keys(seatsByRow).filter(row => 
    seatsByRow[row].some(seat => seat.sezione === section)
  );
  
  const firstPartRows = separatePlatea ? filteredRows.filter(row => row >= "A" && row <= "H") : filteredRows;
  const secondPartRows = separatePlatea ? filteredRows.filter(row => row >= "I" && row <= "S") : [];

  return (
    <Flex direction="column" h="auto">
      {firstPartRows.sort().map(row => <Row seatsByRow={seatsByRow} row={row} section={section} isSeatInCart={isSeatInCart} handleSeatClick={handleSeatClick} key={row} />)}
      {separatePlatea && <Text fontSize={{base:"sm", md:"xl"}} fontWeight="semibold" color="white" alignSelf="center" my={2}>↑ PLATEA ↓</Text>}
      {secondPartRows.sort().map(row => <Row seatsByRow={seatsByRow} row={row} section={section} isSeatInCart={isSeatInCart} handleSeatClick={handleSeatClick} key={row} />)}
    </Flex>
  );
};

const Row = ({ seatsByRow, row, section, isSeatInCart, handleSeatClick }) => (
  <Flex key={row} justify={'center'}>
    {seatsByRow[row].map((seat) => {
      if (seat.sezione !== section) return null;
      const isInCart = isSeatInCart(seat.fila, seat.numero, seat.sezione);
      if (isInCart) seat.stato = "Prenotato";
      
      return (
        <Box key={seat._id} m={{ base: 0.4, md: 2 }}>
          <Button
            size={{ base: "xxs", md: "xl" }}
            w={{ base: "16px", md: "40px" }}
            fontSize={{ base: "7px", md: "14px" }}
            h={{ base: "2vh", md: "4vh" }}
            bg={getColorFromStatus(seat)}
            disabled={(seat.stato !== "Disponibile" && seat.stato !== "Disabili")}
            _hover={getHoverStyle(seat)}
            cursor={(seat.stato === "Disponibile" || seat.stato === 'Disabili') ? "pointer" : "not-allowed"}
            onClick={() => handleSeatClick(seat.fila, seat.numero, seat.sezione)}
            textColor={seat.stato === "Vuoto" ? "transparent" : "default"}
            color={"black"}
          >
            {seat.stato === "Disabili" ? <FaWheelchair color="white" /> : `${seat.fila}${seat.numero}`}
          </Button>
        </Box>
      );
    })}
  </Flex>
);
